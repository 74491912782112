<template>
  <SubviewCard v-if="doctors.length > 0" style="overflow: hidden" noActions>
    <v-layout column>
      <v-flex>
        <DefaultSubtitle>Pozostali terapeuci prowadzący</DefaultSubtitle>
      </v-flex>
      <v-progress-linear
        v-if="loading"
        height="2"
        class="pa-0 ma-0 mt-1 text-center"
        :indeterminate="true"
      ></v-progress-linear>
      <v-layout wrap class="mt-4">
        <OtherMainDoctorsDelegate
          @refreshOwnerCard="$emit('refreshOwnerCard')"
          class="mr-2 my-1"
          :patient="patient"
          v-for="doctor in doctors"
          :isLoading.sync="internalLoading"
          :key="doctor.doctor_id"
          :doctor="doctor"
          :saveBeforeOpen="saveBeforeOpen"
          :fetchAfterClose="fetchAfterClose"
          @success="handleSuccess"
          @error="handleError"
        ></OtherMainDoctorsDelegate>
      </v-layout>
    </v-layout>
  </SubviewCard>
</template>

<script>
import PatientService from "@/services/patient.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    patient: {},
    saveBeforeOpen: {},
    fetchAfterClose: {},
  },
  data() {
    return {
      doctors: [],
    };
  },
  components: {
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    OtherMainDoctorsDelegate: () =>
      import("@/components/delegates/patientcard/OtherMainDoctorsDelegate"),
  },
  methods: {
    fetch() {
      if (this.patient.id == undefined) {
        return;
      }
      this.beforeRequest();
      PatientService.getSessionInfoList(this.patient.id)
        .then((reply) => {
          this.doctors = reply;
          this.reset();
        })
        .catch(this.reset());
    },
  },
  created() {
    this.fetch();
  },
};
</script>